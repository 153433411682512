<template>
  <div class="farm">
    <img :src="$getImgUrl(`assets/farm/${getFarm.title}.jpg`)"  alt="" />
    <p class="farm-title">{{ getFarm.title }}</p>
    <p class="farm-content">
      {{ getFarm.content }}
    </p>
    <!-- 音频 -->
    <audio  controls="controls" :src="$getImgUrl(`assets/farm/${getFarm.title}.mp3`)"  class="audio"></audio>
  </div>
</template>
<script>

import { farm } from "./list";
export default {
  name: "farm",
  components: {},
  data() {
    return {};
  },
  computed: {
    getFarm() {
      return farm.find((n) => n.id == this.$route.query.id) || {};
    },
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
  
<style lang="less" scoped>
.farm {
  height: 100vh;
  overflow-y: auto;
  background: #ffffff;
  padding-bottom: 80px;
  .farm-title {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 15px;
  }
  .farm-content {
    font-size: 16px;
    letter-spacing: 0.5px;
    margin: 10px 15px;
    color: #8e8e8e;
    line-height: 1.8;
  }
}
.audio {
  width: 345px;
  height: 52px;
  margin: auto;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}
</style>